/* General Styles */
.rb-resume-container3 {
  display: flex;
  width: 210mm;  /* A4 width */
  height: 297mm; /* A4 height */
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rb-sidebar3 {
  width: 30%;
  background-color: #2c5977;
  color: #ffffff;
  padding: 20px;
  border-left: 1px solid #2c5977;
}

.rb-main-content3 {
  width: 70%;
  padding: 20px;
}

.rb-profile-section3 {
  text-align: center;
  margin-bottom: 20px;
}

.rb-profile-image3 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

h2, h3 {
  margin-bottom: 10px;
}

/* Sidebar heading styles */
.rb-sidebar3 h3 {
  color: #ffffff;
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.rb-contact-section3, .rb-education-section3, .rb-skills-section3, .rb-language-section3 {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.rb-job {
  margin-bottom: 15px;
}

.rb-reference3 {
  margin-bottom: 10px;
}

/* Main content heading styles */
.rb-main-content3 h3 {
  color: #2c5977;
  border-bottom: 1px solid #2c5977;
  padding-bottom: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.rb-job2 h4 {
  margin-bottom: 5px;
}

.rb-job2 p {
  margin-top: 0;
  color: #7f8c8d;
}

/* Additional styles for sidebar content */
.rb-sidebar3 p, .rb-sidebar3 li {
  font-size: 0.9em;
  line-height: 1.4;
}

/* Styles for the name and title in the profile section */
.rb-profile-section3 h2 {
  font-size: 1.8em;
  margin-bottom: 5px;
  color: #ffffff;
}

.rb-profile-section3 p {
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.83);
}

.rb-bullet3 {
  margin-right: 8px;
  color: #333;
  display: block;
}

/* CSS for the job descriptions to enhance visibility and layout */
.rb-job2 li {
  margin-bottom: 5px;
}

.rb-watermark {
  position: absolute;
  bottom: 0;
  right: 200%;
  transform: translateX(-10%);
  font-size: 1.5rem;
  text-align: right;
  pointer-events: none; /* Prevent interaction with the watermark */
  z-index: 10; /* Ensure it appears above the background */
}

/* Adjustments for A4 print */
@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }


  body * {
      visibility: hidden;
  }

  .rb-resume-container3,
  .rb-resume-container3 *,
  .rb-watermark  {
      visibility: visible;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
  }

  .rb-resume-container3 {
    position: fixed !important;
    width: 210mm !important;
    height: 297mm !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    box-shadow: none !important;
    display: flex !important;
    page-break-inside: avoid !important;
    overflow: hidden !important;
  }

  .rb-sidebar3 {
      width: 30% !important;
      height: 100% !important;
      background-color: #2c5977 !important;
      color: #ffffff !important;
      padding: 20px !important;
  }

  .rb-main-content3 {
      width: 70% !important;
      height: 100% !important;
      padding: 20px !important;
  }

  .rb-profile-section3 h2 {
      color: #ffffff !important;
  }

  .rb-main-content3 h3 {
      color: #2c5977 !important;
      border-bottom: 1px solid #2c5977 !important;
  }
  
  .rb-job2 p {
      color: #7f8c8d !important;
  }

  .buttons-container {
      display: none !important;
  }

  .rb-color1 {
    color:#3D3D3D !important;
  }

  .rb-color2 {
    color:#7C7C7C !important;
  }

  .rb-watermark {
    visibility: visible !important;
    position: fixed !important;
    bottom: 10px !important; /* Adjusted for print area */
    right: 10px !important; /* Placed within the print margin */
    transform: none !important;
    font-size: 1.2rem !important;
    color: rgba(0, 0, 0, 0.2) !important; /* Light opacity for print clarity */
    text-align: right !important;
    z-index: 9999 !important; /* Ensures it's on top */
    pointer-events: none !important;
  }
}
