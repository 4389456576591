/* General Styles */
.rb-resume-container7 {
  display: flex;
  width: 210mm;  /* A4 width */
  height: 297mm; /* A4 height */
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rb-resume-container7::after {
  content: "MicroDegree";
  position: fixed; /* Ensure it stays in place across pages */
  bottom: 20px;
  right: 20px;
  font-size: 20px;
  color: rgba(19, 16, 16, 0.74); /* Temporary red for visibility */
  transform: translate(0%, -50%);
  z-index: 1000;
  pointer-events: none;
}

.rb-sidebar7 {
  width: 30%;
  background-color: #ffffff;
  color:  #7b1113;
  padding: 20px;
}

.rb-main-content7 {
  width: 70%;
  padding: 20px;
}

.rb-profile-section7 {
  text-align: center;
  margin-bottom: 20px;
}

.rb-profile-image7 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

h2, h3 {
  margin-bottom: 10px;
}

/* Sidebar heading styles */
.rb-sidebar7 h3 {
  color: #7b1113;
  border-bottom: 1px solid #7b1113;
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.rb-contact-section7, .rb-education-section7, .rb-skills-section7, .rb-language-section7 {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.rb-job {
  margin-bottom: 15px;
}

.rb-reference7 {
  margin-bottom: 10px;
}

.rb-about-me-section7 {
  background-color: #7b1113;
  color: white;
  padding: 5px;
}

/* Main content heading styles */
.rb-main-content7 h3 {
  color: #7b1113;
  border-bottom: 1px solid #7b1113;
  padding-bottom: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.rb-about-me-section7 h3 {
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.rb-job7 h4 {
  margin-bottom: 5px;
}

.rb-job7 p {
  margin-top: 0;
  color: #7f8c8d;
}

/* Additional styles for sidebar content */
.rb-sidebar7 p, .rb-sidebar7 li {
  font-size: 0.9em;
  line-height: 1.4;
}

/* Styles for the name and title in the profile section */
.rb-profile-section7 h2 {
  font-size: 1.8em;
  margin-bottom: 5px;
  color: #7b1113;
}

.rb-profile-section7 p {
  font-size: 1.2em;
  color: #7b1113;
}

.rb-bullet7 {
  margin-right: 8px; /* Adjust spacing */
  color: #7b1113; /* Customize color */
  display: block;
}

/* CSS for the job descriptions to enhance visibility and layout */
.rb-job7 li {
  margin-bottom: 5px; /* Space between points */
}

.rb-skills-list7 {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between badges */
}

.rb-skill-badge7 {
  background-color: #ffffff; /* White background */
  opacity: 0.7;
  color: black; /* Text color */
  padding: 4px 12px;
  border-radius: 5px; /* Rounded edges for a badge look */
  font-size: 0.9em;
  font-weight: 600;
  border: 1px solid #7b1113;
  display: inline-block;
}

.rb-technologies-list7 {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between badges */
}

.rb-technologies-badge7 {
  background-color: #7b1113; /* White background */
  opacity: 0.7;
  color: #ffffff; /* Text color */
  padding: 4px 12px;
  border-radius: 5px; /* Rounded edges for a badge look */
  font-size: 0.9em;
  font-weight: 600;
  border: 1px solid #7b1113;
  display: inline-block;
}

/* Adjustments for A4 print */
@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
  }

  body * {
    visibility: hidden;
  }

  .rb-resume-container7,
  .rb-resume-container7 * {
    visibility: visible;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .rb-resume-container7 {
    position: fixed !important;
    width: 210mm !important;
    height: 297mm !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    box-shadow: none !important;
    display: flex !important;
    page-break-inside: avoid !important;
    overflow: hidden !important;
  }

  .rb-sidebar7 {
    width: 30% !important;
    height: 100% !important;
    background-color: #ffffff !important;
    color: #7b1113 !important;
    border-right: 1px solid #7b1113 !important;
    padding: 20px !important;
  }

  .rb-main-content7 {
    width: 70% !important;
    height: 100% !important;
    padding: 20px !important;
  }

  .rb-about-me-section7 {
    background-color: #7b1113 !important;
    color: white !important;
  }

  .rb-technologies-badge7 {
    background-color: #7b1113; /* White background */
    opacity: 0.7;
    color: #ffffff; /* Text color */
    padding: 4px 12px;
    border-radius: 5px; /* Rounded edges for a badge look */
    font-size: 0.9em;
    font-weight: 600;
    border: 1px solid #7b1113;
    display: inline-block;
  }

  .rb-main-content7 h3,
  .rb-sidebar7 h3,
  .rb-profile-section7 h2,
  .rb-profile-section7 p,
  .rb-job7 p {
    color: inherit !important;
  }

  .rb-buttons-container {
    display: none !important;
  }

  .rb-color1 {
    color: #3D3D3D !important;
  }

  .rb-color2 {
    color: #7C7C7C !important;
  }

  /* Ensure all text is the intended color for better printing */
  * {
    color: inherit !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  .rb-resume-container7::after {
    content: "MicroDegree";
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    color: rgba(19, 16, 16, 0.74); /* Temporary red for visibility */
    transform: translate(0%, -10%);
    z-index: 1000;
    pointer-events: none;
  }
}
