.rb-resume-container4 {
  font-family: Arial, sans-serif;
  width: 210mm;  /* A4 width */
  height: 297mm; /* A4 height */
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rb-resume-container4::after {
  content: "MicroDegree";
  position: fixed; /* Ensure it stays in place across pages */
  bottom: 20px;
  right: 20px;
  font-size: 20px;
  color: rgba(19, 16, 16, 0.74); /* Temporary red for visibility */
  transform: translate(0%, -50%) ;
  z-index: 1000;
  pointer-events: none;
}

.rb-header4 {
  background-color: #545b77;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rb-header-text h1 {
  margin: 0;
  font-size: 27px;
}

.rb-profile-image4 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.rb-content-wrapper4 {
  display: flex;
}

.rb-main-content4 {
  flex: 3;
  padding: 20px;
}

.rb-sidebar4 {
  flex: 1;
  background-color: #f0f0f0;
  padding: 20px;
  width: 210mm;  /* A4 width */
  height: 260mm; /* A4 height */
}

.rb-main-content4 h2, .rb-sidebar4 h2 {
  color: #545b77;
  border-bottom: 2px solid #545b77;
  padding-bottom: 5px;
  font-size: 23px;
  font-weight: 600;
}

.rb-experience-item4 {
  margin-bottom: 15px;
}

.rb-experience-item4 h3 {
  margin-bottom: 5px;
}

.rb-experience-item4 p {
  margin-top: 0;
  color: #666;
}

.rb-skills-section4 h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #2c3e50; /* Adjust as necessary */
}

.rb-skills-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between badges */
}

.rb-skill-badge4 {
  background-color: #ffffff; /* White background */
  color: #545b77; /* Text color */
  padding: 4px 12px;
  border-radius: 15px; /* Rounded edges for a badge look */
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow */
  border: 1px solid #545b77; /* Optional: adds a border */
  display: inline-block;
}

.rb-skills-list2 {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between badges */
}

.rb-skill-badge42 {
  background-color: #545b77; /* White background */
  color: #ffffff; /* Text color */
  padding: 4px 12px;
  border-radius: 15px; /* Rounded edges for a badge look */
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow */
  border: 1px solid #545b77; /* Optional: adds a border */
  display: inline-block;
}

.rb-color43{
  color: #545b77 !important;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
  } 
  
  body * {
    visibility: hidden;
  }

  .rb-resume-container4,
  .rb-resume-container4 * {
    visibility: visible;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .rb-resume-container4 {
    position: fixed !important;
    width: 210mm !important;
    height: 297mm !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    box-shadow: none !important;
    display: flex !important;
    page-break-inside: avoid !important;
    overflow: hidden !important;
  }

  .rb-header4 {
    background-color: #545b77 !important;
    color: white !important;
    padding: 20px !important;
  }

  .rb-skill-badge4 {
    background-color: #ffffff !important;
    color: #545b77 !important;
  }

  .rb-skill-badge42 {
    background-color: #545b77 !important;
    color: #ffffff !important;
  }
}
