/* Include Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Apply Poppins font globally */
body {
  font-family: 'Poppins', sans-serif;
}


/* Title for the whole page */
.yt-page-title {
  font-size: 48px;
  color: #3d56ec;
  font-weight: 600;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 25px;
}

/* Section title styling */
.section-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #00008b;
  font-weight: 700;
  background-color: #e0e7ff;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  
}

.section {
  margin-bottom: 20px;
}

/* Flexbox layout for video cards */
.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Video card styling with hover effects */
.video-card {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Responsive design for medium and large screens */
@media (min-width: 768px) {
  .video-card {
    width: calc(48% - 20px);
  }
}

@media (min-width: 1024px) {
  .video-card {
    width: calc(30% - 20px);
  }
}

.video-content {
  text-align: center;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
}

.video-name {
  margin-bottom: 5px; /* Space between title and description */
  font-size: 18px; /* Adjust font size if needed */
  font-weight: 500;
}

.video-description {
  margin-top: 5px; /* Space between title and description */
  font-size: 14px; /* Adjust font size if needed */
  color: #555; /* Optional: Adjust text color */
}


/* Enhanced Watch button with hover effects and dynamic icon */
.watch-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s ease;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.watch-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.watch-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Initial Play icon for the Watch button */
.watch-button::before {
  content: "▶️ ";
  margin-right: 8px;
  transition: content 0.3s;
}

/* Toggle between Play and Pause icons */
.watch-button[data-state="pause"]::before {
  content: "⏸️ ";
}


/* HoverTabButton.css */
/* Enquiry button container style */
/* Enquiry button styles */
.enquiry-toggle-button {
  position: fixed;
  bottom: 52px;
  left: 20px;
  background-color: #ff0049;
  color: white;
  border: none;
  padding: 12px 17px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.enquiry-toggle-button:hover {
  background-color: #ff0049;
}

/* Popup styles */
.popup-container {
  position: fixed;
  bottom: 110px;
  left: 20px;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #007bff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 13px;
  z-index: 1000;
  animation: fadeIn 0.5s ease-in-out;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.popup-content {
  position: relative;
}

/* Close button */
.close-btn {
  position: absolute;
  top: -3px;
  right: 4px;
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: black;
}

/* Gif styling - now positioned outside the padding */
.popup-gif {
  width: 43px;
  height: 43px;
  position: absolute; /* Positioned relative to the popup */
  top: -8px; /* Moves it above the padding */
  left: 4px; /* Adjusts the horizontal position */
}

/* Popup message styling */
.popup-message {
  padding-top: 35px; /* Ensures content starts below the GIF */
}

.popup-message p {
  font-size: 13px;
  color: #333;
  margin: 13px 0;
}

/* Enquiry button in popup */
.popup-enquiry-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 13px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 13px;
}

.popup-enquiry-button:hover {
  background-color: #0056b3;
}
/* Sticky Banner Styles */
/*
.sticky-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 9999;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-button {
  background-color: white;
  color: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner-button:hover {
  background-color: #0056b3;
  color: white;
}


.close-banner {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
*/

/* Mobile View */
/*
@media (max-width: 768px) {
  .sticky-banner {
    padding: 15px;
  }

  .banner-content p {
    font-size: 14px;
  }

  .banner-button {
    font-size: 14px;
  }
}
*/

/* Adjust text-size for mobile screens */
/*
@media (max-width: 768px) {
  .sticky-banner {
    font-size: 11;
  }
  .banner-button {
    font-size: 12;
  }
}
*/

/* hiding popup in mobile view */

@media (max-width: 768px) {
  .popup-container {
    display: none;
  }
  .enquiry-toggle-button {
    display: none;
  }  
}


/* Close Button */
/*
.close-banner {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
*/

/* YTFullCoursePageB1.js

.enquiry-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 23px;
  font-size: 23px;
  color: #0b0101;
  background-color: #20c997;
  text-decoration: none;
  border-radius: 10px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s ease;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.enquiry-button:hover {
  background-color: #17e8aa;
}
*/

/*
.enquiry-button:hover {
  background-color: #dc0678;
  transform: translateY(-1px);
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
}
*/

.section-divider-container {
  text-align: center; /* Center align the divider */
  margin: 20px 0; /* Space above and below the container */
}

/* Divider between sections */
.section-divider {
  display: inline-block;
  width: 95%;
  height: 2px;
  background-color: #dcdcdc;
  border: none;
  margin: 20px 0;
}



@media screen and (max-width: 768px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ff0049;
    color: white;
    height: 32px;
    /*
    padding: 5px 6px;
    */
    padding-left: 110px;
    
    text-align: center;
    font-size: 10px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
    
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    font-size: 10px;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:100px;
    height: 23px;
    /*
    left: 10%;
    right: 50%;
    */
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 170px;
  }
  
  .banner-button:hover {
    background-color: #0056b3;
    /*
    color: white;
    */
    color: black;
  }
  
  /* Close Button */
  .close-banner {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

/*
@media screen and (max-width: 576px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: yellow;
    color: white;
    padding: 5px 9px;
    padding-left: 110px;
    
    text-align: center;
    font-size: 11px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:100px;
    height: 23px;
    
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 150px;
  }
  
  .banner-button:hover {
    background-color: #0056b3;
    color: black;
  }
  
}
*/

/*
@media screen and (max-width: 576px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color:yellow;
    color: white;
    padding: 3px 7px;

    text-align:center;
    font-size: 5px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:200px;
    height: 35px;
    

    padding: 2px 4px;
    border-radius: 5px;
    font-size: 9px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .banner-button:hover {
    background-color: #0056b3;
    color: white;
  }
}


@media screen and (max-width: 425px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgb(128, 0, 90);
    color: white;
    padding: 5px 25px;
    padding-left: 12px;
    
    text-align: center;
    font-size: 5px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    gap: 35px;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:90px;
    height: 30px;
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 3px;
    margin-top: -10px;
  }
  
  .banner-button:hover {
    background-color: #0056b3;
    color: black;
  }
}




@media screen and (max-width: 320px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color:purple;
    color: white;
    padding: 1.1px;
    text-align: center;
    font-size: 12.1px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .banner-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:220px;
    height: 40px;
    left: 10%;

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .banner-button:hover {
    background-color: #0056b3;
    color: white;
  }
  
  .close-banner {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
*/

/* Include Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Apply Poppins font globally */
body {
  font-family: 'Poppins', sans-serif;
}


/* Title for the whole page */
.yt-page-title {
  font-size: 48px;
  color: #3d56ec;
  font-weight: 600;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 25px;
}

/* Section title styling */
.section-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #00008b;
  font-weight: 700;
  background-color: #e0e7ff;
  display: inline-block;
  padding: 12px 25px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  
}

.section {
  margin-bottom: 20px;
}

/* Flexbox layout for video cards */
.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Video card styling with hover effects */
.video-card {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Responsive design for medium and large screens */
@media (min-width: 768px) {
  .video-card {
    width: calc(48% - 20px);
  }
}

@media (min-width: 1024px) {
  .video-card {
    width: calc(30% - 20px);
  }
}

.video-content {
  text-align: center;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
}

.video-name {
  margin-bottom: 5px; /* Space between title and description */
  font-size: 18px; /* Adjust font size if needed */
  font-weight: 500;
}

.video-description {
  margin-top: 5px; /* Space between title and description */
  font-size: 14px; /* Adjust font size if needed */
  color: #555; /* Optional: Adjust text color */
}


/* Enhanced Watch button with hover effects and dynamic icon */
.watch-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 6px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s ease;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.watch-button:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.watch-button:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Initial Play icon for the Watch button */
.watch-button::before {
  content: "▶️ ";
  margin-right: 8px;
  transition: content 0.3s;
}

/* Toggle between Play and Pause icons */
.watch-button[data-state="pause"]::before {
  content: "⏸️ ";
}


/* HoverTabButton.css */
/* Enquiry button container style */
/* Enquiry button styles */
.enquiry-toggle-button {
  position: fixed;
  bottom: 52px;
  left: 20px;
  background-color: #ff0049;
  color: white;
  border: none;
  padding: 12px 17px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.enquiry-toggle-button:hover {
  background-color: #ff0049;
}

/* Popup styles */
.popup-container {
  position: fixed;
  bottom: 110px;
  left: 20px;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #007bff;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 13px;
  z-index: 1000;
  animation: fadeIn 0.5s ease-in-out;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.popup-content {
  position: relative;
}

/* Close button */
.close-btn {
  position: absolute;
  top: -3px;
  right: 4px;
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
  color: black;
}

/* Gif styling - now positioned outside the padding */
.popup-gif {
  width: 43px;
  height: 43px;
  position: absolute; /* Positioned relative to the popup */
  top: -8px; /* Moves it above the padding */
  left: 4px; /* Adjusts the horizontal position */
}

/* Popup message styling */
.popup-message {
  padding-top: 35px; /* Ensures content starts below the GIF */
}

.popup-message p {
  font-size: 13px;
  color: #333;
  margin: 13px 0;
}

/* Enquiry button in popup */
.popup-enquiry-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 13px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 13px;
}

.popup-enquiry-button:hover {
  background-color: #0056b3;
}
/* Sticky Banner Styles */
/*
.sticky-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #007bff;
  color: white;
  padding: 10px;
  text-align: center;
  z-index: 9999;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner-button {
  background-color: white;
  color: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner-button:hover {
  background-color: #0056b3;
  color: white;
}


.close-banner {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
*/

/* Mobile View */
/*
@media (max-width: 768px) {
  .sticky-banner {
    padding: 15px;
  }

  .banner-content p {
    font-size: 14px;
  }

  .banner-button {
    font-size: 14px;
  }
}
*/

/* Adjust text-size for mobile screens */
/*
@media (max-width: 768px) {
  .sticky-banner {
    font-size: 11;
  }
  .banner-button {
    font-size: 12;
  }
}
*/

/* hiding popup in mobile view */

@media (max-width: 768px) {
  .popup-container {
    display: none;
  }
  .enquiry-toggle-button {
    display: none;
  }  
}


/* Close Button */
/*
.close-banner {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
*/

/* YTFullCoursePageB1.js

.enquiry-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 13px 23px;
  font-size: 23px;
  color: #0b0101;
  background-color: #20c997;
  text-decoration: none;
  border-radius: 10px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s ease;
  margin-top: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.enquiry-button:hover {
  background-color: #17e8aa;
}
*/

/*
.enquiry-button:hover {
  background-color: #dc0678;
  transform: translateY(-1px);
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.2);
}
*/

.section-divider-container {
  text-align: center; /* Center align the divider */
  margin: 20px 0; /* Space above and below the container */
}

/* Divider between sections */
.section-divider {
  display: inline-block;
  width: 95%;
  height: 2px;
  background-color: #dcdcdc;
  border: none;
  margin: 20px 0;
}


.section-divider-line {
  border: none; 
  height: 1px; 
  background-color: black; 
  margin: 35px auto; 
  width: 95%;
}


@media (prefers-color-scheme: dark) {
  .section-divider-line {
    background-color: white; /* Change to white in dark mode */
  }
}


@media screen and (max-width: 768px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ff0049;
    color: white;
    padding: 20px;
    height: 70px;
    /*
    padding: 5px 6px;
    */
    padding-left: 110px;
    
    text-align: center;
    font-size: 35px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);


    display: flex;
    justify-content: flex-start;
    align-items: center;

    transition: top 0.5s ease, bottom 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
    
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    font-size: 10px;
    white-space: nowrap;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:135px;
    height: 27px;
    /*
    left: 10%;
    right: 50%;
    */
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 170px;
  }
  
  .banner-button:hover {
    background-color: whitesmoke;
    /*
    color: white;
    */
    color: black;
  }
  
  .banner-button:visited {
    color: #ff0049; /* Keeps the original text color after visiting */
  }

  .banner-button:focus {
    outline: none;
  }

  /* When scrolled, move the banner to the top and adjust size */
  .sticky-banner.scrolled {
    top: 0;
    bottom: auto;
    padding: 20px;
    height: 70px;
    padding-left: 110px;
    font-size: 35px;
    opacity: 1;
    transform: translateY(0);
  }
}

/*
@media screen and (max-width: 576px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: yellow;
    color: white;
    padding: 5px 9px;
    padding-left: 110px;
    
    text-align: center;
    font-size: 11px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:100px;
    height: 23px;
    
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 150px;
  }
  
  .banner-button:hover {
    background-color: #0056b3;
    color: black;
  }
  
}
*/

@media screen and (max-width: 576px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ff0049;
    color: white;
    padding: 20px;
    height: 60px;
    
    padding: 2px 8px;
    
    padding-left: 30px;
    text-align: center;
    font-size: 2px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

    display: flex; /* Flexbox to align everything horizontally */
    justify-content: flex-start;
    align-items: center;

    transition: top 0.5s ease, bottom 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
    
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    white-space: nowrap;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:80px;
    height: 23px;
    /*
    left: 10%;
    right: 50%;
    */
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;

    margin-top: -2.5px;
  }
  
  .banner-button:hover {
    background-color: whitesmoke;
    /*
    color: white;
    */
    color: black;
  }

  .banner-button:visited {
    color: #ff0049; /* Keeps the original text color after visiting */
  }

  .banner-button:focus {
    outline: none;
  }

  /* When scrolled, move the banner to the top and adjust size */
  .sticky-banner.scrolled {
    top: 0;
    bottom: auto;
    padding: 20px;
    height: 60px;
    padding-left: 25px;
    font-size: 4px;
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (width: 540px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ff0049;
    color: white;
    padding: 20px;
    height: 57px;
    padding: 2px 8px;
    
    padding-left: 40px;
    text-align: center;
    font-size: 2px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

    display: flex; /* Flexbox to align everything horizontally */
    justify-content: flex-start;
    align-items: center;

    transition: top 0.5s ease, bottom 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
    
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 100px;
    white-space: nowrap;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:93px;
    height: 25px;
    /*
    left: 10%;
    right: 50%;
    */
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;

    margin-top: -3.5px;
  }
  
  .banner-button:hover {
    background-color: whitesmoke;
    /*
    color: white;
    */
    color: black;
  }

  .banner-button:visited {
    color: #ff0049; /* Keeps the original text color after visiting */
  }

  .banner-button:focus {
    outline: none;
  }

  /* When scrolled, move the banner to the top and adjust size */
  .sticky-banner.scrolled {
    top: 0;
    bottom: auto;
    padding: 20px;
    height: 57px;
    padding-left: 40px;
    font-size: 7px;
    opacity: 1;
    transform: translateY(0);
  }

}

@media screen and (max-width: 425px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ff0049;
    color: white;
    padding: 20px;
    height: 60px;
    padding: 2px 8px;
    
    padding-left: 15px;
    text-align: center;
    font-size: 2px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

    display: flex; /* Flexbox to align everything horizontally */
    justify-content: flex-start;
    align-items: center;


    transition: top 0.5s ease, bottom 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 17px;
    white-space: nowrap;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:72px;
    height: 23px;
    /*
    left: 10%;
    right: 50%;
    */
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 2px;

    margin-top: -2.5px;
  }
  
  .banner-button:hover {
    background-color: whitesmoke;
    /*
    color: white;
    */
    color: black;
  }

  .banner-button:visited {
    color: #ff0049; /* Keeps the original text color after visiting */
  }

  .banner-button:focus {
    outline: none;
  }

  /* When scrolled, move the banner to the top and adjust size */
  .sticky-banner.scrolled {
    top: 0;
    bottom: auto;
    padding: 20px;
    height: 60px;
    padding-left: 15px;
    font-size: 4px;
    opacity: 1;
    transform: translateY(0);
  }

}


@media screen and (width: 412px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ff0049;
    color: white;
    padding: 20px;
    height: 65px;
    padding: 2px 8px;
    
    padding-left: 26px;
    text-align: center;
    font-size: 2px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

    display: flex; /* Flexbox to align everything horizontally */
    justify-content: flex-start;
    align-items: center;


    transition: top 0.5s ease, bottom 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    white-space: nowrap;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:72px;
    height: 23px;
    /*
    left: 10%;
    right: 50%;
    */
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 2px;

    margin-top: -2.5px;
  }
  
  .banner-button:hover {
    background-color: whitesmoke;
    /*
    color: white;
    */
    color: black;
  }

  .banner-button:visited {
    color: #ff0049; /* Keeps the original text color after visiting */
  }

  .banner-button:focus {
    outline: none;
  }

  /* When scrolled, move the banner to the top and adjust size */
  .sticky-banner.scrolled {
    top: 0;
    bottom: auto;
    padding: 20px;
    height: 65px;
    padding-left: 26px;
    font-size: 4px;
    opacity: 1;
    transform: translateY(0);
  }

}



@media screen and (width: 360px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ff0049;
    color: white;
    padding: 20px;
    height: 65px;
    padding: 2px 8px;
    padding-left: 15px;
    text-align: center;
    font-size: 2px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: flex-start;
    align-items: center;

    transition: top 0.5s ease, bottom 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
  }

  .banner-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
  }

  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;
    width: 72px;
    height: 23px;
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 2px;
    margin-top: -2.5px;
  }

  .banner-button:hover {
    background-color: whitesmoke;
    color: black;
  }

  .banner-button:visited {
    color: #ff0049; /* Keeps the original text color after visiting */
  }

  .banner-button:focus {
    outline: none;
  }

  /* When scrolled, move the banner to the top and adjust size */
  .sticky-banner.scrolled {
    top: 0;
    bottom: auto;
    padding: 20px;
    height: 65px;
    padding-left: 10px;
    font-size: 4px;
    opacity: 1;
    transform: translateY(0);
  }
}


@media screen and (width: 344px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #ff0049;
    color: white;
    padding: 20px;
    height: 65px;
    padding: 2px 8px;
    
    padding-left: 2px;
    text-align: center;
    font-size: 2px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

    display: flex; /* Flexbox to align everything horizontally */
    justify-content: flex-start;
    align-items: center;


    transition: top 0.5s ease, bottom 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    white-space: nowrap;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:75px;
    height: 23px;
    /*
    left: 10%;
    right: 50%;
    */
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 3px;

    margin-top: -2.5px;
  }
  
  .banner-button:hover {
    background-color: whitesmoke;
    /*
    color: white;
    */
    color: black;
  }

  .banner-button:visited {
    color: #ff0049; /* Keeps the original text color after visiting */
  }

  .banner-button:focus {
    outline: none;
  }

  /* When scrolled, move the banner to the top and adjust size */
  .sticky-banner.scrolled {
    top: 0;
    bottom: auto;
    padding: 20px;
    height: 65px;
    padding-left: 2px;
    font-size: 4px;
    opacity: 1;
    transform: translateY(0);
  }

}


@media screen and (max-width: 320px) {
  .sticky-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    /*
    background-color: #ff0049;
    */
    background-color:#ff0049;
    color: white;
    padding-left: 1px;
    padding: 20px;
    height: 60px;
    text-align:center;
    font-size: 2px;
    z-index: 9999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: flex-start;
    align-items:center;
    transition: top 0.5s ease, bottom 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    opacity: 1;
    transform: translateY(0);
  }
  
  .banner-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px;
    white-space: nowrap;
    
  }
  
  .banner-button {
    background-color: white;
    color: #ff0049;
    border: none;

    width:60px;
    height: 23px;
    /*
    left: 10%;
    right: 50%;
    */
    

    padding: 3px 5px;
    border-radius: 5px;
    font-size: 7px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 1px;

    margin-top: -1.5px;
  }
  
  .banner-button:hover {
    background-color: whitesmoke;
    color: black;
  }
  
  /* Close Button */
  .close-banner {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .banner-button:visited {
    color: #ff0049; /* Keeps the original text color after visiting */
  }

  .banner-button:focus {
    outline: none;
  }

  /* When scrolled, move the banner to the top and adjust size */
  .sticky-banner.scrolled {
    top: 0;
    bottom: auto;
    padding: 20px;
    height: 60px;
    padding-left: 1px;
    font-size: 2px;
    opacity: 1;
    transform: translateY(0);
  }

}