/* General Styles */
.rb-resume-container2 {
  display: flex;
  width: 210mm;  /* A4 width */
  height: 297mm; /* A4 height */
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rb-resume-container2::after {
  content: "MicroDegree";
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 12px;
  color: rgba(19, 16, 16, 0.74);
  transform: translate(0%, -50%);
  z-index: 1000;
  pointer-events: none;
}

.rb-sidebar2 {
  width: 30%;
  background-color: #ffffff;
  color: #2c3e50;
  padding: 20px;
  border-right: 1px solid #2c3e50;
}

.rb-main-content2 {
  width: 70%;
  padding: 20px;
}

.rb-profile-section2 {
  text-align: center;
  margin-bottom: 20px;
}

.rb-profile-image2 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

h2, h3 {
  margin-bottom: 10px;
}

/* Sidebar heading styles */
.rb-sidebar2 h3 {
  color: #2c3e50;
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.rb-contact-section2, .rb-education-section2, .rb-skills-section2, .rb-language-section2 {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

.rb-job {
  margin-bottom: 15px;
}

.rb-reference2 {
  margin-bottom: 10px;
}

.rb-about-me-section2 {
  background-color: #2c3e50;
  color: white;
  padding: 5px;
}

.rb-exp-section2 {
  background-color: none;
  color: black;
  padding: 5px;
}

/* Main content heading styles */
.rb-main-content2 h3 {
  color: #2c3e50;
  border-bottom: 1px solid #2c3e50;
  padding-bottom: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.rb-about-me-section2 h3 {
  color: white;
  border-bottom: 1px solid white;
  padding-bottom: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.rb-job2 h4 {
  margin-bottom: 5px;
}

.rb-job2 p {
  margin-top: 0;
  color: #7f8c8d;
}

/* Additional styles for sidebar content */
.rb-sidebar2 p, .rb-sidebar2 li {
  font-size: 0.9em;
  line-height: 1.4;
}

/* Styles for the name and title in the profile section */
.rb-profile-section2 h2 {
  font-size: 1.8em;
  margin-bottom: 5px;
  color: #2c3e50;
}

.rb-profile-section2 p {
  font-size: 1.2em;
  color: #2c3e50;
}

.rb-bullet2 {
  margin-right: 0px;
  color: #333;
  display: block;
}

/* CSS for the job descriptions to enhance visibility and layout */
.rb-job2 li {
  margin-bottom: 5px;
}

/* Adjustments for A4 print */
@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }


  body * {
    visibility: hidden;
  }

  .rb-resume-container2,
  .rb-resume-container2 * {
    visibility: visible;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .rb-resume-container2 {
    position: fixed !important;
    width: 210mm !important;
    height: 297mm !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    box-shadow: none !important;
    display: flex !important;
    page-break-inside: avoid !important;
    overflow: hidden !important;
  }

  .rb-sidebar2 {
    width: 30% !important;
    height: 100% !important;
    background-color: #ffffff !important;
    color: #2c3e50 !important;
    border-right: 1px solid #2c3e50 !important;
    padding: 20px !important;
  }

  .rb-main-content2 {
    width: 70% !important;
    height: 100% !important;
    padding: 20px !important;
  }

  .rb-about-me-section2 {
    background-color: #2c3e50 !important;
    color: white !important;
  }

  .rb-color1 {
    color:#3D3D3D !important;
  }

  .rb-color2 {
    color:#7C7C7C !important;
  }

  * {
    color: inherit !important;
    text-shadow: none !important;
    filter: none !important;
  }

  .rb-resume-container2::after {
    content: "MicroDegree";
    position: fixed;
    bottom: 0px;
    right: 20px;
    font-size: 20px;
    color: rgba(19, 16, 16, 0.74);
    transform: translate(0%, -50%);
    z-index: 1000;
    pointer-events: none;
  }
}
