.rb-resume-container8 {
  font-family: Arial, sans-serif;
  width: 210mm;  /* A4 width */
  height: 297mm; /* A4 height */
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rb-header8 {
  background-color: #845460;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rb-header-text h1 {
  margin: 0;
  font-size: 27px;
}

.rb-profile-image8 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.rb-content-wrapper8 {
  display: flex;
}

.rb-main-content8 {
  flex: 3;
  padding: 20px;
}

.rb-sidebar8 {
  flex: 1;
  background-color: #f3f1eb;
  padding: 20px;
  width: 210mm;  /* A4 width */
  height: 260mm; /* A4 height */
}

.rb-main-content8 h2, .rb-sidebar8 h2 {
  color: #845460;
  border-bottom: 2px solid #845460;
  padding-bottom: 5px;
  font-size: 23px;
  font-weight: 600;
}

ul {
  padding-left: 20px;
}

.rb-experience-item8 {
  margin-bottom: 15px;
}

.rb-experience-item8 h3 {
  margin-bottom: 5px;
}

.rb-experience-item8 p {
  margin-top: 0;
  color: #666;
}

.rb-skills-section8 h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #845460; /* Adjust as necessary */
}

.rb-skills-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between badges */
}

.rb-skill-badge {
  background-color: #ffffff; /* White background */
  color: #845460; /* Text color */
  padding: 4px 12px;
  border-radius: 15px; /* Rounded edges for a badge look */
  font-size: 0.9em;
  font-weight: 600; 
  border: 1px solid #845460; /* Optional: adds a border */
  display: inline-block;
}

.rb-skills-list2 {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between badges */
}

.rb-skill-badge2 {
  background-color: #845460; /* White background */
  color: #ffffff; /* Text color */
  padding: 4px 12px;
  border-radius: 15px; /* Rounded edges for a badge look */
  font-size: 0.9em;
  font-weight: 600;
  border: 1px solid #845460; /* Optional: adds a border */
  display: inline-block;
}


@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
  } 
  
  body * {
    visibility: hidden;
  }

  .rb-resume-container8,
  .rb-resume-container8 * {
    visibility: visible;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .rb-resume-container8 {
    position: fixed !important;
    width: 210mm !important;
    height: 297mm !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    box-shadow: none !important;
    display: flex !important;
    page-break-inside: avoid !important;
    overflow: hidden !important;
  }

  .rb-header8 {
    background-color: #845460 !important;
    color: white !important;
    padding: 20px !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  .rb-header-text h1 {
    margin: 0 !important;
    font-size: 27px !important;
  }

  .rb-profile-image8 {
    width: 100px !important;
    height: 100px !important;
    border-radius: 50% !important;
  }

  .rb-content-wrapper8 {
    display: flex !important;
  }

  .rb-main-content8 {
    flex: 3 !important;
    padding: 20px !important;
  }

  .rb-sidebar8 {
    flex: 1 !important;
    background-color: #f0f0f0 !important;
    padding: 20px !important;
    width: 210mm !important;
    height: 280mm !important;
  }

  .rb-main-content8 h2,
  .rb-sidebar8 h2 {
    color: #845460 !important;
    border-bottom: 2px solid #845460 !important;
    padding-bottom: 5px !important;
    font-size: 23px !important;
    font-weight: 600 !important;
  }

  ul {
    padding-left: 20px !important;
  }

  .rb-experience-item8 {
    margin-bottom: 15px !important;
  }

  .rb-experience-item8 h3 {
    margin-bottom: 5px !important;
  }

  .rb-experience-item8 p {
    margin-top: 0 !important;
    color: #666 !important;
  }

  .rb-skills-section8 h2 {
    font-size: 1.5em !important;
    margin-bottom: 15px !important;
    color: #845460 !important;
  }

  .rb-skills-list,
  .rb-skills-list2 {
    list-style-type: none !important;
    padding-left: 0 !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 10px !important;
  }

  .rb-skill-badge,
  .rb-skill-badge2 {
    padding: 4px 12px !important;
    border-radius: 15px !important;
    font-size: 0.9em !important;
    font-weight: 600 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    border: 1px solid !important;
    display: inline-block !important;
  }

  .rb-skill-badge {
    background-color: #ffffff !important;
    color: #845460 !important;
  }

  .rb-skill-badge2 {
    background-color: #845460 !important;
    color: #ffffff !important;
  }

  .rb-color3 {
    color: #845460 !important;
  }

  .rb-resume-container8::after {
    content: "MicroDegree";
    position: fixed; /* Ensure it stays in place across pages */
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    color: rgba(19, 16, 16, 0.74); /* Temporary red for visibility */
    transform: translate(0%, -50%);
    z-index: 1000;
    pointer-events: none;
  }
}
