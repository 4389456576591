.rb-resume-container {
  display: flex;
  width: 210mm; /* A4 width */
  height: 297mm; /* A4 height */
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.rb-sidebar {
  width: 30%;
  background-color: #2c3e50;
  color: #ffffff;
  padding: 20px;
}

.rb-main-content {
  width: 70%;
  padding: 20px;
}

.rb-profile-section {
  text-align: center;
  margin-bottom: 20px;
}

.rb-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.rb-h2, .rb-h3 {
  margin-bottom: 10px;
}

/* Sidebar heading styles */
.rb-sidebar h3 {
  color: #ffffff;
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.rb-contact-section,
.rb-education-section,
.rb-skills-section,
.rb-language-section {
  margin-bottom: 20px;
}

.rb-ul {
  list-style-type: none;
  padding-left: 0;
}

.rb-job {
  margin-bottom: 15px;
}

.rb-reference {
  margin-bottom: 10px;
}

.rb-watermark {
  position: absolute;
  bottom: 0;
  right: 200%;
  transform: translateX(-10%);
  font-size: 1.5rem;
  text-align: right;
  pointer-events: none; /* Prevent interaction with the watermark */
  z-index: 10; /* Ensure it appears above the background */
}

.rb-watermark-micro {
  color: blue;
}

.rb-watermark-degree {
  color: orange;
}

/* Adjustments for A4 print */
@media print {
  /* Reset the page settings */
  @page {
    size: A4 portrait;
    margin: 0;
  }

  /* Hide everything initially */
  body * {
    visibility: hidden;
  }

  /* Show only the resume container and its contents */
  .rb-resume-container,
  .rb-resume-container *,
  .rb-watermark  {
    visibility: visible;
  }

  /* Position and size the resume container for A4 */
  .rb-resume-container {
    position: fixed !important;
    width: 210mm !important;
    height: 297mm !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    box-shadow: none !important;
    page-break-inside: avoid !important;
  }

  .rb-watermark {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 1.5rem;
    pointer-events: none; /* Prevent interaction with the watermark */
    z-index: 10; /* Ensure it appears above other content */
  }


  /* Adjust sidebar and main content for proper A4 proportions */
  .rb-sidebar {
    width: 30% !important;
    height: 297mm !important;
    padding: 20px !important;
    background-color: #2c3e50 !important;
    color: #ffffff !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .rb-main-content {
    width: 70% !important;
    height: 297mm !important;
    padding: 20px !important;
  }

  /* Ensure proper color printing */
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  /* Hide additional elements */
  .rb-buttons-container {
    display: none !important;
  }


  /* Ensure text colors print correctly */
  .rb-color1 {
    color: #3D3D3D !important;
  }

  .rb-color2 {
    color: #7C7C7C !important;
  }

  /* Ensure headings print properly */
  .rb-main-content h3 {
    color: #2c3e50 !important;
    border-bottom: 1px solid #2c3e50 !important;
    padding-bottom: 5px !important;
    font-size: 1.3em !important;
    font-weight: bold !important;
  }

  /* Ensure profile section prints properly */
  .rb-profile-section h2 {
    color: #ffffff !important;
  }

  .rb-profile-section p {
    color: rgba(255, 255, 255, 0.83) !important;
  }
}

/* Non-print styles remain unchanged */
.rb-main-content h3 {
  color: #2c3e50;
  border-bottom: 1px solid #2c3e50;
  padding-bottom: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.rb-job h4 {
  margin-bottom: 5px;
}

.rb-job p {
  margin-top: 0;
  color: #7f8c8d;
}

.rb-sidebar p,
.rb-sidebar li {
  font-size: 0.9em;
  line-height: 1.4;
}

.rb-profile-section h2 {
  font-size: 1.8em;
  margin-bottom: 5px;
  color: white;
}

.rb-profile-section p {
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.83);
}

.rb-bullet {
  margin-right: 8px;
  color: #333;
  display: block;
}

.rb-job li {
  margin-bottom: 5px;
}





