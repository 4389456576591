.rb-resume-container5 {
  display: flex;
  width: 210mm;  /* A4 width */
  height: 297mm; /* A4 height */
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rb-resume-container5::after {
  content: "MicroDegree";
  position: fixed; /* Ensure it stays in place across pages */
  bottom: 20px;
  right: 20px;
  font-size: 20px;
  color: rgba(19, 16, 16, 0.74); /* Temporary red for visibility */
  transform: translate(0%, -50%) ;
  z-index: 1000;
  pointer-events: none;
}

.rb-sidebar5 {
  width: 30%;
  background-color: #086464;
  color: #ffffff;
  padding: 20px;
}

.rb-main-content5 {
  width: 70%;
  padding: 20px;
}

.rb-profile-section5 {
  text-align: center;
  margin-bottom: 20px;
}

.rb-profile-image5 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

h2, h3 {
  margin-bottom: 10px;
}

/* Sidebar heading styles */
.rb-sidebar5 h3 {
  color: #ffffff;
  font-size: 1.4em;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.rb-contact-section5, .rb-education-section5, .rb-skills-section5, .rb-language-section5 {
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

.rb-job5 {
  margin-bottom: 15px;
}

.rb-reference5 {
  margin-bottom: 10px;
}

/* Adjustments for A4 print */
@media print {
  @page {
    size: A4 !important; /* Ensures the correct page size */
    margin: 0 !important; /* Removes default browser margins */
  }

  body {
    margin: 0 !important;
    padding: 0 !important;
  } 

  .rb-resume-container5 {
    width: 210mm;
    height: 297mm;
    margin: 0;
    box-shadow: none;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }
}

/* Main content heading styles */
.rb-main-content5 h3 {
  color: #086464;
  border-bottom: 1px solid #086464;
  padding-bottom: 5px;
  font-size: 1.3em;
  font-weight: bold;
}

.rb-job5 h4 {
  margin-bottom: 5px;
}

.rb-job5 p {
  margin-top: 0;
  color: #7f8c8d;
}

/* Additional styles for sidebar content */
.rb-sidebar5 p, .rb-sidebar5 li {
  font-size: 0.9em;
  line-height: 1.4;
}

/* Styles for the name and title in the profile section */
.rb-profile-section5 h2 {
  font-size: 1.8em;
  margin-bottom: 5px;
  color: white;
}

.rb-profile-section5 p {
  font-size: 1.2em;
  color: rgba(255, 255, 255, 0.83);
}

.rb-bullet5 {
  margin-right: 8px; /* Adjust spacing */
  color: #333; /* Customize color */
  display: block;
}

/* CSS for the job descriptions to enhance visibility and layout */
.rb-job5 li {
  margin-bottom: 5px; /* Space between points */
}

.rb-skills-list {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Space between badges */
}

.rb-skill-badge {
  background-color: #ddd6d6; /* White background */
  opacity: 0.7;
  color: black; /* Text color */
  padding: 4px 12px;
  border-radius: 15px; /* Rounded edges for a badge look */
  font-size: 0.9em;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a slight shadow */
 
  display: inline-block;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }

  body * {
    visibility: hidden;
  }

  .rb-resume-container5,
  .rb-resume-container5 * {
    visibility: visible;
  }

  .rb-resume-container5 {
    position: fixed !important;
    width: 210mm !important;
    height: 297mm !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
    box-shadow: none !important;
    display: flex !important;
    page-break-inside: avoid !important;
    overflow: hidden !important;
  }

  .rb-sidebar5,
  .rb-main-content5 {
    display: block !important;
    float: none !important;
    position: static !important;
    overflow: visible !important;
  }

  .rb-sidebar5 {
    width: 30% !important;
    height: 100% !important;
    padding: 20px !important;
    background-color: #086464 !important;
    color: #ffffff !important;;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .rb-main-content5 {
    width: 70% !important;
    height: 100% !important;
    padding: 20px !important;
  }

  .rb-main-content5 h3 {
    color: #086464 !important;
    border-bottom: 1px solid #086464 !important;
    padding-bottom: 5px;
    font-size: 1.3em;
    font-weight: bold;
  }
  
  .rb-job5 h4 {
    margin-bottom: 5px;
  }
  
  .rb-job5 p {
    margin-top: 0;
    color: #7f8c8d;
  }
  
  /* Additional styles for sidebar content */
  .rb-sidebar5 p, .rb-sidebar5 li {
    font-size: 0.9em;
    line-height: 1.4;
  }
  
  /* Styles for the name and title in the profile section */
  .rb-profile-section5 h2 {
    font-size: 1.8em;
    margin-bottom: 5px;
    color: white;
  }
  
  .rb-profile-section5 p {
    font-size: 1.2em;
    color: rgba(255, 255, 255, 0.83);
  }
  
  .rb-bullet5 {
    margin-right: 8px; /* Adjust spacing */
    color: #333; /* Customize color */
    display: block;
  }
  
  /* CSS for the job descriptions to enhance visibility and layout */
  .rb-job5 li {
    margin-bottom: 5px; /* Space between points */
  }

  .rb-buttons-container5 {
    display: none !important;
  }

  .rb-color1{
     color:#3D3D3D !important;
  }

  .rb-color2{
    color:#7C7C7C !important;
  }

  /* Ensure all text is the intended color for better printing */
  * {
    color: inherit !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  /* Force background colors to print */
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .rb-resume-container5::after {
    content: "MicroDegree";
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    color: rgba(19, 16, 16, 0.74); /* Temporary red for visibility */
    transform: translate(0%, -10%) ;
    z-index: 1000;
    pointer-events: none;
  }
}
